import { Box, Container, Skeleton } from '@mui/material';

import * as Styles from '../styles';

export default function LifeMomentSkeleton() {
  return (
    <Box component="section" sx={{ backgroundColor: '#212121' }}>
      <Container maxWidth="xl" sx={Styles.SectionWrapper} disableGutters>
        <Skeleton
          variant="text"
          height={100}
          width="100%"
          sx={{
            backgroundColor: '#646464',
            maxWidth: '930px',
            mx: 'auto',
            mb: { xs: 5, md: 11 },
          }}
        />

        <Box component="section">
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box sx={Styles.ImageWrapper}>
              <Skeleton
                variant="rectangular"
                height="100%"
                width="100%"
                sx={{ backgroundColor: '#646464' }}
              />
            </Box>

            <Box
              sx={{
                ...Styles.TextWrapper,
                backgroundColor: '#181818',
              }}
            >
              <Box display="flex">
                <Skeleton
                  variant="rectangular"
                  width="48px"
                  height="48px"
                  sx={{ backgroundColor: '#646464', mr: 2 }}
                />
                <Skeleton
                  variant="rectangular"
                  width="48px"
                  height="48px"
                  sx={{ backgroundColor: '#646464', mr: 2 }}
                />
                <Skeleton
                  variant="rectangular"
                  width="48px"
                  height="48px"
                  sx={{ backgroundColor: '#646464', mr: 2 }}
                />
              </Box>

              <Skeleton
                variant="text"
                height="60px"
                sx={{ backgroundColor: '#646464' }}
              />

              <Skeleton
                variant="text"
                height="100px"
                sx={{ backgroundColor: '#646464' }}
              />

              <Skeleton
                variant="rectangular"
                width="240px"
                height="56px"
                sx={{ backgroundColor: '#646464' }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row-reverse' }}
          >
            <Box sx={Styles.ImageWrapper}>
              <Skeleton
                variant="rectangular"
                height="100%"
                width="100%"
                sx={{ backgroundColor: '#646464' }}
              />
            </Box>

            <Box sx={{ ...Styles.TextWrapper, backgroundColor: '#181818' }}>
              <Box display="flex">
                <Skeleton
                  variant="rectangular"
                  width="48px"
                  height="48px"
                  sx={{ backgroundColor: '#646464' }}
                />
              </Box>

              <Skeleton
                variant="text"
                height="60px"
                sx={{ backgroundColor: '#646464' }}
              />

              <Skeleton
                variant="text"
                height="100px"
                sx={{ backgroundColor: '#646464' }}
              />

              <Skeleton
                variant="rectangular"
                width="240px"
                height="56px"
                sx={{ backgroundColor: '#646464' }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
