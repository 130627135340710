export const SectionTitle = {
  color: (theme) => theme.palette.background.primary.dark,
  fontWeight: 800,
  mb: { xs: 5 },
  mx: 'auto',
  textAlign: 'center',
};

export const SectionWrapper = {
  px: { xs: 2, sm: 4, md: 8, lg: 14, xl: 27 },
  py: { xs: 5, md: 8 },
};
