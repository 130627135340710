import { Box, Skeleton } from '@mui/material';

export default function TestimonySkeleton() {
  return (
    <Box
      sx={{
        backgroundColor: '#FDF0F0',
        maxWidth: { xs: '100%', md: '342px' },
        width: '100%',
      }}
    >
      <Box height={{ xs: '225px', md: '420px' }} position="relative">
        <Skeleton variant="rectangular" height="100%" width="100%" />
      </Box>

      <Box px={3} py={4}>
        <Skeleton variant="text" height="200px" />

        <Skeleton height="100px" variant="text" />
      </Box>
    </Box>
  );
}
