import { Box, Container, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TestimonySkeleton from 'components/HomeComponents/Testimonies/Testimony/Skeleton';

import * as Styles from '../styles';

export default function TestimoniesSkeleton() {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box component="section" bgcolor="#FCFAFA">
      <Container maxWidth="xl" sx={Styles.SectionWrapper} disableGutters>
        <Skeleton
          variant="text"
          height="100px"
          width="100%"
          sx={{
            maxWidth: '980px',
            mx: 'auto',
            mb: { xs: 5, md: 11 },
          }}
        />

        <Box
          display="flex"
          alignItems="center"
          gap={5}
          justifyContent="space-between"
        >
          {[1, 2, 3, 4].map((key) => {
            if (mobileBreakpoint && key > 1) return null;

            return <TestimonySkeleton key={key} />;
          })}
        </Box>
      </Container>
    </Box>
  );
}
