import Head from 'next/head';
import Dynamic from 'next/dynamic';
import { Box } from '@mui/material';

import Header from 'components/Shared/Header';
import Hero from 'components/HomeComponents/Hero';

import FluencyJourneySkeleton from 'components/Shared/FluencyJourney/Skeleton';
import LifeMomentSkeleton from 'components/HomeComponents/LifeMoment/Skeleton';
import TestimoniesSkeleton from 'components/HomeComponents/Testimonies/Skeleton';
import CorporateSkeleton from 'components/HomeComponents/Corporate/Skeleton';
import { MediaPress } from 'templates/PlansTemplate/imports';

import { groupURL, cicleURL } from 'utils/constants/homepageImages';

import { HomeMenuNav, HomeTabs } from 'mocks/menuNav';
import { testimoniesHomeFull } from 'mocks/testimonies';

import { getSetup } from 'services/accountService';
import { getCookie } from 'cookies-next';

const FluencyJourney = Dynamic(
  () => import('components/Shared/FluencyJourney'),
  {
    ssr: false,
    loading: () => <FluencyJourneySkeleton />,
  }
);

const LifeMoment = Dynamic(
  () => import('components/HomeComponents/LifeMoment'),
  {
    ssr: false,
    loading: () => <LifeMomentSkeleton />,
  }
);

const Corporate = Dynamic(() => import('components/HomeComponents/Corporate'), {
  ssr: false,
  loading: () => <CorporateSkeleton />,
});

const Testimonies = Dynamic(
  () => import('components/HomeComponents/Testimonies'),
  {
    ssr: false,
    loading: () => <TestimoniesSkeleton />,
  }
);

const Footer = Dynamic(() => import('components/Shared/Footer'), {
  ssr: true,
});

export async function getServerSideProps({ req, res }) {
  const runningInWebView = getCookie('runningInWebView', { req, res });

  // eslint-disable-next-line no-new-wrappers
  if (new Boolean(runningInWebView).valueOf() === true) {
    return {
      redirect: {
        permanent: false,
        destination: '/account',
      },
    };
  }

  const setup = await getSetup(req, res, true);
  if (setup && setup.setupSteps.length > 0) {
    return {
      redirect: {
        permanent: false,
        destination: '/setup',
      },
    };
  }

  return {
    props: {
      blurImages: {
        groupURL,
        cicleURL,
      },
    },
  };
}

export default function HomePage({ blurImages }) {
  return (
    <Box component="main">
      <Head>
        <meta
          content="Fluencypass - O único ciclo completo da fluência"
          key="title"
          property="og:title"
        />
        <meta
          content="Estude inglês desde já com opção de intercâmbio no final, ou simplesmente faça seu intercâmbio."
          key="description"
          property="og:description"
        />
      </Head>

      <Header
        menuNavHeader={HomeMenuNav}
        menuNavSideMenu={HomeMenuNav}
        tabs={HomeTabs}
      />

      <Hero blurImages={blurImages} />

      <MediaPress />

      <FluencyJourney hideCTA />

      <LifeMoment />

      <Corporate />

      {/* <Kids /> */}

      <Testimonies testimonies={testimoniesHomeFull} />

      <Footer />
    </Box>
  );
}
