export const groupURL =
  'https://res.cloudinary.com/intercambiodireto-com/f_auto,q_auto,c_scale,w_750/Others/Heros/hero-people_kyct0d';
export const cicleURL =
  'https://res.cloudinary.com/intercambiodireto-com/f_auto,c_limit,w_640,q_auto/Others/Heros/semi-cycle_rvejph';

export const Cicle = {
  alignSelf: 'center',
  bottom: 0,
  height: { xs: '147px', sm: '188px', lg: '220px', xl: '242px' },
  left: { xl: 'calc(50% - 480px)' },
  position: 'absolute',
  width: '100%',
};

export const SectionWrapper = {
  px: { xs: 0, sm: 0, md: 8, lg: 14, xl: 27 },
};

export const BackgroundImage = {
  backgroundImage: { xs: 'none', md: `url(${groupURL})` },
  backgroundPosition: 'right',
  backgroundRepeat: 'no-repeat',
  backgroundSize: { sm: 0, md: '50% 550px', lg: '50%' },
};

export const Group = {
  display: { xs: 'block' },
  height: { xs: '350px', md: '550px' },
  position: 'relative',
};

export const TextWrapper = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: { xs: '460px', md: '550px' },
  px: {
    xs: 2,
    sm: 4,
    md: 0,
  },
  pt: 5.5,
};
