export const SectionTitle = {
  maxWidth: '980px',
  mb: { xs: 5, sm: 8 },
  mx: 'auto',
  px: { xs: 4 },
  textAlign: 'center',
};

export const bgColor = {
  backgroundColor: '#000',
};

export const SectionWrapper = {
  px: { xs: 0, sm: 4, md: 8, lg: 14, xl: 27 },
  py: { xs: 0, sm: 8 },
  pt: { xs: 6, md: 8 },
};

export const ImageWrapper = {
  height: { xs: '340px', sm: '420px', md: '400px', lg: '440px' },
  position: 'relative',
  textAlign: 'center',
  width: { xs: '100%', md: '50%' },
};

export const TextWrapper = {
  backgroundColor: '#212121',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: { xs: 'auto', md: '400px', lg: '440px' },
  py: { xs: 2, lg: 10 },
  px: { xs: 2, lg: 6 },
  width: { xs: '100%', md: '50%' },
};
