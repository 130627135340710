import { Typography, Box, Container, Grid } from '@mui/material';
import Image from 'next/image';

import { groupURL, cicleURL } from 'utils/constants/homepageImages';

import * as Styles from './styles';

export default function Hero({ blurImages }) {
  return (
    <Box
      color="primary.contrastText"
      id="hero"
      component="section"
      sx={{
        background: 'linear-gradient(180deg, #670213 39.91%, #2A1D1D 123.82%)',
        minHeight: { xs: '460px', md: '550px' },
      }}
    >
      <Container maxWidth="xl" disableGutters sx={Styles.SectionWrapper}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={Styles.TextWrapper} width="100%">
              <Typography
                component="h1"
                fontWeight={700}
                variant="h2"
                mt={3}
                sx={{ lineHeight: 1 }}
              >
                Inglês +<br />
                Intercâmbio
              </Typography>

              <Typography
                component="h2"
                fontWeight={400}
                maxWidth="585px"
                mt={1}
                variant="subtitle1"
              >
                O ciclo completo para você falar inglês definitivamente. Aulas
                24/7, conversação ilimitada, aula particular e intercâmbio.
              </Typography>

              <Box sx={Styles.Cicle}>
                <Image
                  blurDataURL={blurImages.circle}
                  alt="Fluencypass, intercâmbio, inglês e conversação."
                  fill
                  loader={({ src, width }) => `${src}?w=${width}`}
                  priority
                  sizes="(max-width: 960px) 10vw, (max-width: 1200px) 15vw"
                  src={cicleURL}
                  style={{ objectFit: 'contain' }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={Styles.Group} width="100%">
              <Image
                blurDataURL={blurImages.group}
                alt="Dois homens e uma mulher sorrindo"
                fill
                loader={({ src, width }) => `${src}?w=${width}`}
                priority
                sizes="(max-width: 960px) 100vw, (max-width: 1200px) 50vw"
                src={groupURL}
                style={{ objectFit: 'cover' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
