import PropTypes from 'prop-types';
import { Box, Container, Skeleton } from '@mui/material';

import * as Styles from '../styles';

CorporateSkeleton.propTypes = {
  reverse: PropTypes.bool,
};

CorporateSkeleton.defaultProps = {
  reverse: false,
};

export default function CorporateSkeleton({ reverse }) {
  return (
    <Box component="section" sx={{ backgroundColor: '#000' }}>
      <Container maxWidth="xl" disableGutters sx={Styles.SectionWrapper}>
        <Skeleton
          animation="wave"
          variant="text"
          height="120px"
          width="100%"
          sx={{
            maxWidth: '980px',
            mx: 'auto',
            mb: '16px',
            backgroundColor: '#616161',
          }}
        />

        <Box
          display="flex"
          alignItems="center"
          flexDirection={{
            xs: 'column',
            md: reverse ? 'row-reverse' : 'row',
          }}
        >
          <Box sx={{ ...Styles.ImageWrapper, backgroundColor: '#616161' }}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height="100%"
              width="100%"
            />
          </Box>

          <Box sx={{ ...Styles.TextWrapper }}>
            <Skeleton
              variant="text"
              height="80px"
              sx={{ backgroundColor: '#616161' }}
            />

            <Skeleton
              variant="text"
              height="130px"
              sx={{ backgroundColor: '#616161' }}
            />

            <Skeleton
              variant="rectangular"
              width="240px"
              height="56px"
              sx={{ backgroundColor: '#616161' }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
