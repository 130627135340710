import { Skeleton, Box, Container, Grid } from '@mui/material';

import { SectionWrapper } from '../styles';

export default function FluencyJourneySkeleton() {
  return (
    <Box component="section" bgcolor="background.secondary.main">
      <Container maxWidth="xl" sx={SectionWrapper} disableGutters>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} md={5}>
            <Skeleton variant="text" height="130px" width="100%" />

            {[1, 2, 3].map((key) => (
              <Box mb={3} key={key} display="flex" alignItems="center">
                <Skeleton
                  variant="rectangular"
                  width="60px"
                  height="60px"
                  sx={{ mr: 2 }}
                />
                <Skeleton
                  variant="text"
                  height="90px"
                  width="calc(100% - 80px)"
                />
              </Box>
            ))}

            <Skeleton variant="rectangular" width="240px" height="56px" />
          </Grid>

          <Grid item md={7}>
            <Skeleton
              variant="rectangular"
              sx={{ height: { xs: '340px', md: '540px' } }}
              width="100%"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
